import { SourceID } from '../../atoms/mappers/wizard/enums/sourceId'
import { WizardLanguage } from '../../atoms/mappers/wizard/enums/language'

export const INITIAL_CATALOG_FILTERS = {
  isWorkingWithTeenagerPatients: false,
  friendliness: false,
  psychologistAge: [24, 65],
  workAreas: [],
  approaches: [],
  prices: [],
  sourceId: SourceID.UC_12XK08,
  language: WizardLanguage.Russian,
  isUrgent: false
}
